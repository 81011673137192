<template>
<div class="">
  <div class="footer">
    <div class="introduce">
      <dl class="companyInfo">
        <dt>
          <img src="../assets/img/index/logo_bottom.png" alt="">
        </dt>
        <dd class="item" @click="join()" v-if="show">
          <img src="../assets/img/index/icon_factory.png" alt="">
          印刷厂入驻
        </dd>
      </dl>
      <dl class="area">
        <dt class="item">
          <h4>无限立方</h4>
        </dt>
        <dd class="item">
          <a href="http://taiheiot.com/about/introduction" target="_blank">公司介绍</a>
        </dd>
        <dd class="item">
          <a href="http://taiheiot.com/about/honor" target="_blank">企业荣誉</a>
        </dd>
        <dd class="item">
          <a href="http://taiheiot.com/about/join" target="_blank">加入我们</a>
        </dd>
      </dl>
      <dl class="area">
        <dt class="item">
          <h4>产品服务</h4>
        </dt>
<!--        <dd class="item">-->
<!--          <a :href="`${$url}/#/OnlineDesign/Selection`" target="_blank">在线设计</a>-->
<!--        </dd>-->
        <dd class="item">
          <a :href="`${$url}/#/MarketingActivities`" target="_blank">营销活动</a>
        </dd>
<!--        <dd class="item">-->
<!--          <a :href="`${$url}/#/OnlinePrinting`" target="_blank">在线印刷</a>-->
<!--        </dd>-->
      </dl>
      <dl class="area">
        <dt class="item">
          <h4>帮助中心</h4>
        </dt>
        <dd class="item">
          <a href="javascript:void(0)" >帮助文档</a>
        </dd>
        <dd class="item">
          <a :href="`${$url}/#/PrivacyAgreement`"  target="_blank">隐私策略</a>
        </dd>
        <dd class="item">
          <a :href="`${$url}/#/UserAgreement`"  target="_blank">用户协议</a>
        </dd>
      </dl>
      <dl class="area">
        <dt class="item">
          <h4>联系我们</h4>
        </dt>
        <dd class="item">
          <span>电话：86-755-86098666</span>
        </dd>
        <dd class="item">
          <span>传真：86-755-86011222</span>
        </dd>
        <dd class="item">
          <span>地址：深圳市南山区侨香路4068号智慧广场18楼</span>
        </dd>
      </dl>
    </div>
  </div>
  <div class="copyright">
    Copyright © 2015 深圳市太和物联信息技术有限公司 All Rights Reserved <a href="https://beian.miit.gov.cn">粤ICP备08010402号</a>
    <a href="https://szcert.ebs.org.cn/aa4ef582-92a4-4324-b680-095b5bbeb372" target="_blank">
      <img src="https://szcert.ebs.org.cn/Images/govIcon.gif" alt="深圳市市场监督管理局企业主体身份公示" title="深圳市市场监督管理局企业主体身份公示">
    </a>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'footer',
  data () {
    return {}
  },
  computed: {
    ...mapState(['show'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #313131;
  height: 240px;
  .introduce {
    height: 150px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    .area {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      .item {
        text-align: left;
        a,
        span {
          color: #888888;
          font-size: 14px;
        }
        h4 {
          color: #BABABA;
        }
      }
    }
    .companyInfo {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      color: #666;
      font-size: 14px;
      .item {
        position: relative;
        width: 118px;
        height: 28px;
        background: #313131;
        border: 1px solid #181818;
        line-height: 28px;
        text-align: center;
        border-radius: 2px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BABABA;
        cursor: pointer;
      }
    }
  }
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #666;
  height: 64px;
  background-color: #181818;
  img {
    width: 20px;
    vertical-align: bottom;
    cursor: pointer;
    margin-left: 5px;
    line-height: auto;
  }
  a {
    color: #666;
  }
}
</style>
