var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "introduce" }, [
        _c("dl", { staticClass: "companyInfo" }, [
          _vm._m(0),
          _vm.show
            ? _c(
                "dd",
                {
                  staticClass: "item",
                  on: {
                    click: function($event) {
                      return _vm.join()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../assets/img/index/icon_factory.png"),
                      alt: ""
                    }
                  }),
                  _vm._v(" 印刷厂入驻 ")
                ]
              )
            : _vm._e()
        ]),
        _vm._m(1),
        _c("dl", { staticClass: "area" }, [
          _vm._m(2),
          _c("dd", { staticClass: "item" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$url + "/#/MarketingActivities",
                  target: "_blank"
                }
              },
              [_vm._v("营销活动")]
            )
          ])
        ]),
        _c("dl", { staticClass: "area" }, [
          _vm._m(3),
          _vm._m(4),
          _c("dd", { staticClass: "item" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$url + "/#/PrivacyAgreement",
                  target: "_blank"
                }
              },
              [_vm._v("隐私策略")]
            )
          ]),
          _c("dd", { staticClass: "item" }, [
            _c(
              "a",
              {
                attrs: { href: _vm.$url + "/#/UserAgreement", target: "_blank" }
              },
              [_vm._v("用户协议")]
            )
          ])
        ]),
        _vm._m(5)
      ])
    ]),
    _vm._m(6)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("img", {
        attrs: { src: require("../assets/img/index/logo_bottom.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "area" }, [
      _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("无限立方")])]),
      _c("dd", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: {
              href: "http://taiheiot.com/about/introduction",
              target: "_blank"
            }
          },
          [_vm._v("公司介绍")]
        )
      ]),
      _c("dd", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: { href: "http://taiheiot.com/about/honor", target: "_blank" }
          },
          [_vm._v("企业荣誉")]
        )
      ]),
      _c("dd", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: { href: "http://taiheiot.com/about/join", target: "_blank" }
          },
          [_vm._v("加入我们")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("产品服务")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("帮助中心")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", { staticClass: "item" }, [
      _c("a", { attrs: { href: "javascript:void(0)" } }, [_vm._v("帮助文档")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "area" }, [
      _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("联系我们")])]),
      _c("dd", { staticClass: "item" }, [
        _c("span", [_vm._v("电话：86-755-86098666")])
      ]),
      _c("dd", { staticClass: "item" }, [
        _c("span", [_vm._v("传真：86-755-86011222")])
      ]),
      _c("dd", { staticClass: "item" }, [
        _c("span", [_vm._v("地址：深圳市南山区侨香路4068号智慧广场18楼")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _vm._v(
        " Copyright © 2015 深圳市太和物联信息技术有限公司 All Rights Reserved "
      ),
      _c("a", { attrs: { href: "https://beian.miit.gov.cn" } }, [
        _vm._v("粤ICP备08010402号")
      ]),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://szcert.ebs.org.cn/aa4ef582-92a4-4324-b680-095b5bbeb372",
            target: "_blank"
          }
        },
        [
          _c("img", {
            attrs: {
              src: "https://szcert.ebs.org.cn/Images/govIcon.gif",
              alt: "深圳市市场监督管理局企业主体身份公示",
              title: "深圳市市场监督管理局企业主体身份公示"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }