var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home_wrap" },
    [
      _c(
        "div",
        { staticClass: "banner" },
        _vm._l(_vm.bannerContentList, function(item, index) {
          return _c("div", { key: index, staticClass: "bannerContent" }, [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(item.title) + " ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "liList" },
              _vm._l(item.liList, function(li, i) {
                return _c("div", { key: i, staticClass: "li" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(li.label))
                  ]),
                  _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(li.desc))])
                ])
              }),
              0
            )
          ])
        }),
        0
      ),
      _c("div", { staticClass: "templateTitle" }, [_vm._v("数百块活动模板")]),
      _c(
        "div",
        { staticClass: "classify" },
        _vm._l(_vm.classify, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "classifyItem",
              class: { choose: _vm.classIndex == index },
              on: {
                click: function($event) {
                  _vm.classIndex = index
                }
              }
            },
            [
              _c("div", { staticClass: "icon" }),
              _vm._v(" " + _vm._s(item) + " ")
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "classTemplate" },
        _vm._l(_vm.template[_vm.classIndex], function(item) {
          return _c(
            "div",
            {
              key: item.imgId,
              staticClass: "template",
              on: {
                click: function($event) {
                  return _vm.$router.push("OwnWorkbench/OwnActivity")
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://wxyx-xcx.obs.cn-south-1.myhuaweicloud.com/lfPC/home/" +
                    item.imgId +
                    ".png",
                  alt: ""
                }
              }),
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))])
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "template-more",
          on: {
            click: function($event) {
              return _vm.$router.push("OwnWorkbench/OwnActivity")
            }
          }
        },
        [
          _vm._v(" 查看更多 "),
          _c("i", {
            staticClass: "el-icon-sort-up",
            attrs: { "data-v-1744366b": "" }
          })
        ]
      ),
      _c("Foot")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }