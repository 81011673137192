<template>
  <div class="home_wrap">
    <div class="banner">
      <div class="bannerContent" v-for="(item, index) of bannerContentList" :key="index">
        <div class="header">
          <div class="title">
            {{ item.title }}
          </div>
        </div>
        <div class="liList">
          <div class="li" v-for="(li, i) of item.liList" :key="i">
            <div class="label">{{ li.label }}</div>
            <div class="desc">{{ li.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="templateTitle">数百块活动模板</div>
    <div class="classify">
      <div class="classifyItem" :class="{choose: classIndex == index}"
           v-for="(item, index) of classify" @click="classIndex = index"
           :key="index">
        <div class="icon"></div>
        {{ item }}
      </div>
    </div>
    <div class="classTemplate">
      <div class="template" v-for="(item) of template[classIndex]"
           @click="$router.push('OwnWorkbench/OwnActivity')"
           :key="item.imgId">
        <img :src="`https://wxyx-xcx.obs.cn-south-1.myhuaweicloud.com/lfPC/home/${item.imgId}.png`" alt="">
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
    <div class="template-more" @click="$router.push('OwnWorkbench/OwnActivity')">
      查看更多
      <i data-v-1744366b="" class="el-icon-sort-up"></i>
    </div>
    <Foot />
  </div>
</template>

<script>
import Foot from '@/components/footer.vue'
export default {
  name: 'home',
  components: {
    Foot
  },
  data () {
    return {
      bannerContentList: [
        {
          title: '品牌营销',
          liList: [
            {
              label: '分享活动',
              desc: '分享品牌，增加品牌曝光度'
            },
            {
              label: '裂变活动',
              desc: '增加粉丝裂变，提高品牌竞争力'
            }
          ]
        },
        {
          title: '获客拉新',
          liList: [
            {
              label: '红包活动',
              desc: '各种抽奖模式，更有经典大转盘活动'
            },
            {
              label: '涨粉活动',
              desc: '粉丝裂变，增加粉丝拉客'
            },
            {
              label: '线下联动活动',
              desc: '线下门店活动'
            }
          ]
        },
        {
          title: '活跃留存',
          liList: [
            {
              label: '签到活动',
              desc: '灵活签到，丰富配置，每日吸粉'
            },
            {
              label: '养成活动',
              desc: '各大电商同款养成游戏'
            }
          ]
        },
        {
          title: '粉丝转化',
          liList: [
            {
              label: '秒杀活动',
              desc: '简单方便，生成各类秒杀活动'
            },
            {
              label: '拼团活动',
              desc: '简单方便，生成拼团活动'
            }
          ]
        }
      ],
      classify: ['商业促销', '在线抽奖', '游戏营销', '小程序活动', '投票活动'],
      classIndex: 0,
      template: {
        0: [
          {
            imgId: '05',
            title: '欢乐娃娃机'
          }, {
            imgId: '08',
            title: '扭蛋有礼'
          }, {
            imgId: '13',
            title: '疯狂世界杯'
          }, {
            imgId: '19',
            title: '大富翁寻宝'
          }, {
            imgId: '07',
            title: '现场抽奖'
          }, {
            imgId: '02',
            title: '游泳竞赛'
          }, {
            imgId: '03',
            title: '接力跑挑战赛'
          }, {
            imgId: '04',
            title: '点球大战'
          }],
        1: [
          {
            imgId: '06',
            title: '红包树'
          }, {
            imgId: '11',
            title: '欢乐集卡'
          }, {
            imgId: '12',
            title: '接好运红包'
          }, {
            imgId: '20',
            title: '海报拉新有礼'
          }
        ],
        2: [
          {
            imgId: '01',
            title: '数字谜盘'
          }, {
            imgId: '09',
            title: '南北大PK'
          }, {
            imgId: '10',
            title: '答题抽奖'
          }, {
            imgId: '15',
            title: '滚蛋吧，负能量'
          }
        ],
        3: [
          {
            imgId: '16',
            title: '一起养小兔'
          }, {
            imgId: '17',
            title: '趣味拼拼乐'
          }, {
            imgId: '18',
            title: '温暖回家路'
          }, {
            imgId: '21',
            title: '幸运九宫格'
          }
        ],
        4: [
          {
            imgId: '14',
            title: '最美照片征集令'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./css/home.scss";
</style>
